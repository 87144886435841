<template>
  <div class="view pa24">
    <div class="d-flex">
        <el-button class="ma" type="primary" @click="handleAddDealer('add')">+添加操作员</el-button>
    </div>
    <commonTable
      :tableData="tableData"
      :total="total"
      :currentPage="pageNum"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:table>

        <el-table-column
          prop="nickName"
          align="center"
          label="姓名"
        />
        <el-table-column
          prop="phone"
          align="center"
          label="账号"
        />
        <el-table-column
            prop="position"
            align="center"
            label="岗位"
        />
        <el-table-column
            prop="createTime"
            align="center"
            label="创建时间"
        />
        <el-table-column align="center" width="200" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="editDealar(scope.row)">编辑</el-button>
            <el-button type="text" @click="deleteOperator(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </template>
    </commonTable>

    <AddOperatorDialog ref="addOperatorDialog" @getList="getList"></AddOperatorDialog>
  </div>
</template>

<script>
import AddOperatorDialog from './components/AddOperatorDialog.vue'
import commonTable from "@/components/common/commonTable";
import { operatorPage,deleteOperator } from '@/api/warehouseManage.js'

export default {
  name: "dealerList",
  components: {
    commonTable,
    AddOperatorDialog,
  },
  data() {
    return {
        tableData: [],
        pageSize: 10,
        pageNum: 1,
        total: 0,
    };
  },
  created() {
    this.getList()
  },
  methods: {
      handleSizeChange(num) {
        this.pageSize = num;
        this.pageNum = 1;
        this.getList();
      },
      handleCurrentChange(num) {
        this.pageNum = num;
        this.getList();
      },
      handleAddDealer(type, id) {
          this.$refs.addOperatorDialog.openDialog(type, id)
      },
      getList() {
        let params={
          pageNum: this.pageNum,
          pageSize: this.pageSize,
        }
        operatorPage(params).then(res => {
          this.tableData = res.data.list
          this.total = res.data.total
        })
      },
      editDealar(data) {
        this.$refs.addOperatorDialog.openDialog('edit', data)
      },
      deleteOperator(data) {
        let params={
          id: data.userId
        }
        this.$confirm('此操作将永久删除该操作员, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          deleteOperator(JSON.stringify(params)).then(res => {
            if(res.code === 200) {
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.getList()
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });

      }
  }
}
</script>

<style lang="scss" scoped></style>

