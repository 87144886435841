<template>
  <div class="view pa24">

    <el-dialog
        :title="title"
        width="800px"
        :modal-append-to-body="false"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :before-close="closeDialog"
    >
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="operatorForm"
          label-width="150px"
          class="ruleForm"
      >
        <el-form-item label="姓名" prop="nickName" >
          <el-input v-model="ruleForm.nickName"></el-input>
        </el-form-item>
        <el-form-item label="账号(手机号)" prop="phone">
          <el-input v-model="ruleForm.phone"></el-input>
        </el-form-item>
        <p style="color: red;margin-left: 150px;margin-top: -13px;margin-bottom: 10px;">注：手机号默认为系统账号</p>
        <el-form-item label="密码" >
          <el-input type="password" show-password v-model="ruleForm.password"></el-input>
        </el-form-item>
        <el-form-item label="岗位"  >
          <el-input v-model="ruleForm.position"></el-input>
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog">取 消</el-button>
        <el-button class="btncss" @click="confirmAddDealer">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import BaiduMap from 'vue-baidu-map/components/map/Map.vue'
import {addOperator} from '@/api/warehouseManage.js'

export default {
  components: {

  },
  props: [],

  data() {
    return {
      source: '',
      //测试
      dialogVisible: false,
      title: '添加操作员',
      ruleForm: {
        nickName:"",
        phone:"",
        password:"",
        userId:null,
        position:''
      },
      rules: {
        nickName: [
          {required: true, message: '请输入操作员姓名', trigger: 'blur'},
        ],
        phone: [
          {required: true, message: '请输入操作员账号', trigger: 'blur'},
        ],
      },
      type: 'add',
      id: '',

    };
  },
  onShow() {

  },
  methods: {
    openDialog(type, data) {
      this.dialogVisible = true
      let v = this
      if (type === 'add') {
        this.title = '添加操作员'
        this.type = 'add'
        this.id = ''
      } else {
        this.title = '编辑操作员'
        this.type = "edit"
        this.ruleForm = data
      }
    },
    closeDialog() {
      this.dialogVisible = false
      this.resetForm()
      this.$emit('getList')
    },
    resetForm() {
      this.$refs.operatorForm.resetFields();
    },
    confirmAddDealer() {
      this.$refs.operatorForm.validate((valid) => {
        if (valid) {
          addOperator(this.ruleForm).then(res => {
            if (res.code === 200) {
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.closeDialog()
            }
          })
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.ml10 {
  margin-left: 10 rpx;
}
</style>
